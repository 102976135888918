<template>
    <el-dialog :visible.sync="detailDisabled" @open="open" :modal-append-to-body="false" :modal="false"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="1700px">
        <div class="x-page-container" ref="resize">
            <div>
                <x-radio-group v-model="search.period" @change="radioChange" dictType="T_PERIOD"/>
            </div>
            <!-- 查询区 -->
            <div class="x-page-search">
                <x-search-item label="时间" textWidth="220px">
                    <el-date-picker placeholder="起始时间" v-model="search.xiaoFeiSJBegin" size="small" type="datetime"/>
                    <el-date-picker placeholder="结束时间" v-model="search.xiaoFeiSJEnd" size="small" type="datetime"
                                    slot="end"/>
                </x-search-item>
                <x-search-item label="订单号">
                    <el-input v-model="search.dingDanHao" size="small"/>
                </x-search-item>
                <x-search-item label="订单类型">
                    <x-selector-one v-model="search.dingDanLX" size="small" dictType="T_ORDERS"/>
                </x-search-item>
                <div class="x-search-item">
                    <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                    <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                    <excel-export :tableData="exportTableData" :headAndKey="headAndKey" :isSum="true" :condition="condition"
                                  fileName="收款明细表">
                        <el-button type="success" size="mini" style="margin-left: 10px">导出</el-button>
                    </excel-export>
                </div>
            </div>
            <div class="table-top-card-box">
                <x-card class="shou-kuan-card" label="销售笔数" :text="cardData.xsbs"></x-card>
                <x-card class="shou-kuan-card" label="销售金额" :text="cardData.xsze"></x-card>
                <x-card class="shou-kuan-card" label="退款笔数" :text="cardData.tkbs"></x-card>
                <x-card class="shou-kuan-card" label="退款金额" :text="cardData.tkze"></x-card>
                <x-card class="shou-kuan-card" label="实收金额" :text="cardData.ssze"></x-card>
                <x-card class="shou-kuan-card" label="成本" :text="cardData.cb"></x-card>
                <x-card class="shou-kuan-card" label="盈利" :text="cardData.yl"></x-card>
                <x-card class="shou-kuan-card" label="利润率" :text="cardData.lrl"></x-card>
            </div>
            <!-- 列表区域 -->
            <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                      @selection-change="handleSelectionChange">
                <el-table-column width="50" label="序号" type="index" fixed/>
                <el-table-column width="100" label="类型">
                    <x-dict-show slot-scope="{row}" :code="row.dingDanLX" dictType="T_ORDERS"/>
                </el-table-column>
                <el-table-column width="180" prop="dingDanHao" label="订单号"/>
                <el-table-column width="180" prop="xiaoFeiSJ" label="售出时间"/>
                <el-table-column width="180" prop="huiYuanMC" label="消费对象"/>
                <el-table-column width="180" label="支付类型">
                    <x-dict-show slot-scope="{row}" :code="row.zhiFuLX" dictType="T_PAY"/>
                </el-table-column>
                <el-table-column width="180" label="支付金额">
                    <template slot-scope="scope">
                        <span v-if="tkddDict===scope.row.dingDanLX">-{{scope.row.zhiFuJinE}}</span>
                        <span v-else>{{scope.row.zhiFuJinE}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" prop="chengBenHJ" label="成本合计"/>
                <el-table-column width="180" prop="youHuiJuanDK" label="优惠券"/>
                <el-table-column width="180" prop="moLing" label="抹零"/>
                <el-table-column width="180" prop="huiYuanYouHui" label="会员折扣"/>
                <el-table-column width="180" prop="liRun" label="利润"/>
                <el-table-column width="180" prop="liRunLv" label="利润率"/>
                <el-table-column width="250" align="center" label="操作" fixed="right">
                    <template slot-scope="{ row, $index }">
                        <el-button type="success" size="mini" round @click="handleToDetail(row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination background
                           :total="table.total"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           layout="total, sizes, prev, pager, next, jumper"/>
            <shou-kuan-m-x-detail ref="skmxDetail"/>

        </div>
    </el-dialog>
</template>

<script>
    import * as service from "@/service/jygl/ShouKuanMX";
    import XTableBase from "@/components/x/XTableBase";
    import XCard from "@/components/x/card/XCard";
    import {JYGL_SKMX_CARD_SUFFIX, KONG_ZHI_XIAN_SHI,DICT_TYPE_PERIOD,DICT_TYPE_ORDERS} from '@/util/constant'
    import {deepCopy, deepMerge} from "@/util/objects";
    import {getDictType, initDictByType} from "@/util/dict";
    import ShouKuanMXDetail from "@/view/jygl/skmx/ShouKuanMXDetail";
    export default {
        name: "TongJiBaoBiaoDetail",
        mixins: [XTableBase],
        components: {ShouKuanMXDetail, XCard},
        beforeCreate() {
            this.defaultCardData = {
                xsbs: '0',
                xsze: '0',
                tkbs: '0',
                tkze: '0',
                ssze: '0',
                cb: '0',
                yl: '0',
                lrl: '0'
            };
        },
        data(){
            this.refreshService = service.getXiaoShouBaoBiaoList;
            this.service = service;
            return {
                detailDisabled:false,
                ownerDeptCode:"",
                search: {
                    period:"",
                    dingDanHao: '',
                    xiaoFeiSJBegin: '',
                    xiaoFeiSJEnd: '',
                    dingDanLX: '',
                    ownerDeptCode:'',
                },
                headAndKey: {
                    dingDanHao: "订单号",
                    xiaoFeiSJ: "售出时间",
                    huiYuanMC: "消费对象",
                    zhiFuLX: "支付类型",
                    zhiFuJinE: "支付金额",
                    chengBenHJ: "成本合计",
                    youHuiJuanDK: "优惠券",
                    moLing: "抹零",
                    huiYuanYouHui: "会员折扣",
                    liRun: "利润",
                    iRunLv: "利润率",
                },
                exportTableData: [],
                condition: [],
                tkddDict:DICT_TYPE_ORDERS.THDD.key,
                cardData: deepCopy(this.defaultCardData),
                bi: JYGL_SKMX_CARD_SUFFIX.BI.name,
                yuan: JYGL_SKMX_CARD_SUFFIX.YUAN.name,
                bfh:JYGL_SKMX_CARD_SUFFIX.BFH.name,
                payDict: getDictType("T_PAY"),
            }
        },
        created() {
            initDictByType('T_PAY')
        },
        methods:{
            open(){
                this.refresh()
            },
            //单选按钮组变化调用方法
            radioChange(val){
                if(val){
                    if(DICT_TYPE_PERIOD.RXS.key===val){
                        this.search.xiaoFeiSJBegin = new Date(new Date().setHours(0,0,0,0))
                    }else if(DICT_TYPE_PERIOD.ZXS.key===val){
                        let weekFirstDate = new Date()
                        let weekDay = weekFirstDate.getDay()||7;
                        weekFirstDate.setDate(weekFirstDate.getDate()-weekDay+1)
                        weekFirstDate.setHours(0,0,0,0)
                        this.search.xiaoFeiSJBegin = weekFirstDate
                    }else if(DICT_TYPE_PERIOD.YXS.key===val){
                        let yueFirstDate = new Date()
                        yueFirstDate.setDate(1)
                        yueFirstDate.setHours(0,0,0,0)
                        this.search.xiaoFeiSJBegin = yueFirstDate
                    }else if(DICT_TYPE_PERIOD.NXS.key===val){
                        let yearFirstDate = new Date()
                        yearFirstDate.setDate(1)
                        yearFirstDate.setMonth(0)
                    }
                    this.search.xiaoFeiSJEnd = new Date()
                }
            },
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                this.getShouKuanTJ()
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    if (this.table.data) {
                        this.table.data.forEach(item => {
                            if(!item.huiYuanMC){
                                item.huiYuanMC="散客"
                            }
                            if (!item.moLing) {
                                item.moLing = KONG_ZHI_XIAN_SHI.WML.name
                            }
                            item.liRunLv = item.liRunLv.concat(this.bfh)
                        })
                        this.exportTableData = deepCopy(this.table.data)
                        this.exportTableData.forEach(item => {
                            let pay = this.payDict.find(element => element.key === item.zhiFuLX)
                            item.zhiFuLX = pay.value
                        })
                    }
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            //获取卡片相关统计数据
            getShouKuanTJ() {
                this.service.getShouKuanMXTongJi(this.search).then((response) => {
                    this.cardData = deepMerge(this.defaultCardData, response.data)
                    if (this.cardData) {
                        this.cardData.xsbs = this.cardData.xsbs.concat(this.bi)
                        this.cardData.xsze = this.cardData.xsze.concat(this.yuan)
                        this.cardData.tkbs = this.cardData.tkbs.concat(this.bi)
                        this.cardData.tkze = this.cardData.tkze.concat(this.yuan)
                        this.cardData.ssze = this.cardData.ssze.concat(this.yuan)
                        this.cardData.cb = this.cardData.cb.concat(this.yuan)
                        this.cardData.yl = this.cardData.yl.concat(this.yuan)
                        this.cardData.lrl = this.cardData.lrl.concat(this.bfh)

                    }
                })
            },
            handleToDetail(row) {
                this.$refs.skmxDetail.detailVisible = true
                this.$refs.skmxDetail.shouKuanMX = row
            },
        }
    }
</script>

<style scoped>
    .page-tips {
        padding: 8px 16px;
        background-color: #ecf8ff;
        border-radius: 4px;
        border-left: 5px solid #409EFF;
        font-size: 13px;
        color: #5e6d82;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    .table-top-card-box {
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        /* justify-content: space-between; */
    }

    .shou-kuan-card {
        width: 11%;
    }
</style>
