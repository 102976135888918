<template>
    <div class="x-page-container" ref="resize">

        <el-tabs tab-position="left" v-model="tongJiTabName" @tab-click="tongJiClick">
            <el-tab-pane label="库存统计" name="kuCun">
                <el-tabs @tab-click="kuCunClick" v-model="kuCunTabName">
                    <el-tab-pane label="普通商品" name="puTong">
                        <el-table ref="tableOne" v-loading="table.loading" :data="table.data" :height="table.height" border>
                            <el-table-column width="60" label="序号" type="index"/>
                            <el-table-column prop="menDianMC" label="门店"/>
                            <el-table-column label="商品种类" align="center">
                                <el-table-column prop="feiChengZhongSL" label="非称重(种)"/>
                                <el-table-column prop="chengZhongSL" label="称重(种)"/>
                            </el-table-column>
                            <el-table-column prop="shangPinHeJi" label="商品合计(种)"/>
                            <el-table-column prop="jinJiaHeJi" label="进价合计(元)"/>
                            <el-table-column prop="yuShouHeJi" label="预售合计(元)"/>
                            <el-table-column width="250" align="center" label="操作">
                                <template slot-scope="{ row, $index }">
                                    <el-button type="primary" size="mini" round @click="kuCunTongJiDetail(row)">查看详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="规格商品" name="guiGe">
                        <el-table ref="tableTwo" v-loading="table.loading" :data="table.data" :height="table.height" border>
                            <el-table-column width="50" label="序号" type="index"/>
                            <el-table-column prop="menDianMC" label="门店"/>
                            <el-table-column label="商品种类" align="center">
                                <el-table-column prop="feiChengZhongSL" label="非称重(种)"/>
                                <el-table-column prop="chengZhongSL" label="称重(种)"/>
                            </el-table-column>
                            <el-table-column prop="shangPinHeJi" label="商品合计(种)"/>
                            <el-table-column prop="jinJiaHeJi" label="进价合计(元)"/>
                            <el-table-column prop="yuShouHeJi" label="预售合计(元)"/>
                            <el-table-column width="250" align="center" label="操作">
                                <template slot-scope="{ row, $index }">
                                    <el-button type="primary" size="mini" round @click="kuCunTongJiDetail(row)">查看详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="销售统计" name="xiaoShou">
                <el-table ref="tableThree" v-loading="xstjTable.loading" :data="xstjTable.data" :height="xstjTable.height" border>
                    <el-table-column width="50" label="序号" type="index" fixed/>
                    <el-table-column prop="menDianMC" label="门店"/>
                    <el-table-column label="销售">
                        <el-table-column prop="xsbs" label="笔数"/>
                        <el-table-column prop="xsze" label="金额"/>
                    </el-table-column>
                    <el-table-column label="退款">
                        <el-table-column prop="tkbs" label="笔数"/>
                        <el-table-column prop="tkze" label="金额"/>
                    </el-table-column>
                    <el-table-column prop="ssze" label="实际金额"/>
                    <el-table-column prop="cb" label="成本"/>
                    <el-table-column prop="yl" label="盈利"/>
                    <el-table-column prop="lrl" label="利润率"/>
                    <el-table-column width="250" align="center" label="操作" fixed="right">
                        <template slot-scope="{ row, $index }">
                            <el-button type="primary" size="mini" round @click="tongJibaoBiaoDetail(row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <ku-cun-tong-ji-detail ref="kuCunTongJi"/>
        <ku-cun-bao-biao-detail ref="kuCunBaoBiao"/>
        <tong-ji-bao-biao-detail ref="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import {getMdShouKuanMXTongJi} from "@/service/jygl/ShouKuanMX";
    import {deepCopy} from "@/util/objects"
    import XTableBase from "@/components/x/XTableBase";
    import {checkboxList} from "@/service/mdgl/MenDianXX"
    import KuCunTongJiDetail from "@/view/mdgl/tjbb/KuCunTongJiDetail";
    import KuCunBaoBiaoDetail from "@/view/mdgl/tjbb/KuCunBaoBiaoDetail";
    import TongJiBaoBiaoDetail from "@/view/mdgl/tjbb/TongJiBaoBiaoDetail";
    import {DICT_YES_OR_NO} from "@/util/constant"

    export default {
        name: "TongJiBaoBiaoList",
        mixins: [XTableBase],
        components: {KuCunBaoBiaoDetail, KuCunTongJiDetail,TongJiBaoBiaoDetail},
        beforeCreate(){
            this.defaultTable={
                loading: false,
                    data: [],
                    total: 0,
                    height: 700
            }
        },
        data() {
            this.refreshService = service.kuCunTongJiList;
            this.service = service;
            this.searchNoClear = {current: 1, size: 10};
            this.sels = [];
            this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            return {
                defaultTable:{},
                tongJiTabName:"kuCun",
                kuCunTabName:"puTong",
                baoBiaoTabName:"puTong",
                table: deepCopy(this.defaultTable),
                xstjTable:deepCopy(this.defaultTable),
                baoBiaoContent:"库存报表为：商户调拨至门店或门店同步商户的商品",
                options: [],
                searchSelect: 1,
                search: {
                    shangPinMC: '',
                    shangPinHuoHao: '',
                    shangPinTiaoMa: '',
                    guiShuMenDian: '',
                    shiFuDuoGuiGe:"false"
                },
                searChParamMid: '',
                menDianList:[],
                compoundOptions: [
                    {id: 1, name: '名称'},
                    {id: 2, name: '货号'},
                    {id: 3, name: '条码'},
                ],
            }
        },
        mounted() {
            this.refresh();
            this.getMenDianSelectList()
        },
        methods: {
            getMenDianSelectList(){
                checkboxList().then((res)=>{
                    this.menDianList = res.data
                })
            },
            tongJibaoBiaoDetail(row){
                // let ownerDeptCode = row.ownerDeptCode
                this.$refs.detail.detailDisabled = true
                this.$refs.detail.search.ownerDeptCode = row.ownerDeptCode
            },
            kuCunClick(tab, event) {
                if(tab.index==0){
                    this.search.shiFuDuoGuiGe = DICT_YES_OR_NO.NO.key
                }else{
                    this.search.shiFuDuoGuiGe = DICT_YES_OR_NO.YES.key
                }
                this.refresh()
            },
            baoBiaoClick(tab,event){
                if(tab.index==0){
                    this.search.shiFuDuoGuiGe = DICT_YES_OR_NO.NO.key
                }else{
                    this.search.shiFuDuoGuiGe = DICT_YES_OR_NO.YES.key
                }
                this.refresh()
            },
            xstjRefresh(){
              this.xstjTable.loading = true
                return getMdShouKuanMXTongJi().then((response) => {
                    this.xstjTable.data = response.data
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.xstjTable.loading = false)
            },
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    if(this.tongJiTabName=='baoBiao'){
                        this.table.data = ret.records;
                        this.table.total = ret.total || 0;
                        //处理11条数据删除一条不显示问题
                        if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                            this.searchNoClear.current--
                            this.refresh()
                            return
                        }
                    }else{
                        this.table.data = response.data
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            tongJiClick(tab, event){
                if(tab.index==0){
                    this.kuCunTabName = "puTong"
                    this.refreshService = this.service.kuCunTongJiList
                    this.kuCunClick(tab,event)
                }else if(tab.index==1){
                    this.xstjRefresh()
                }else{
                    this.baoBiaoTabName = "puTong"
                    Object.keys(this.search).forEach((key) => this.search[key] = '')
                    this.search.shiFuDuoGuiGe = 'false'
                    this.refreshService = this.service.kuCunBaoBiaoList
                    tab.index=0
                    this.baoBiaoClick(tab,event)
                }
            },
            kuCunTongJiDetail(row){
                this.$refs.kuCunTongJi.search.guiShuMenDian = row.guiShuMenDian
                this.$refs.kuCunTongJi.dialogVisible = true
            },
            kuCunBaoBiaoDetailShow(row){
                if(row.shangHuShangPinId){
                    this.$refs.kuCunBaoBiao.search.shangHuShangPinId = row.shangHuShangPinId
                }else{
                    this.$refs.kuCunBaoBiao.search.shangHuShangPinId = row.id
                }
                if(this.baoBiaoTabName == "puTong"){
                    this.$refs.kuCunBaoBiao.title = row.shangPinMC+"-详情"
                }else{
                    this.$refs.kuCunBaoBiao.title = row.shangPinMC+"("+row.ciJiGuiGeMC+")"+"-详情"
                }
                this.$refs.kuCunBaoBiao.showDisabled = true
            },
            xiaoShouTongJiDetail(row){

            },
            handleSearch() {
                if (this.searchSelect == 1) {
                    this.search.shangPinMC = this.searChParamMid
                } else if (this.searchSelect == 2) {
                    this.search.shangPinHuoHao = this.searChParamMid
                } else if (this.searchSelect == 3) {
                    this.search.shangPinTiaoMa = this.searChParamMid
                }
                this.searchNoClear.current = 1;
                return this.refresh();
            },
            //清空
            handleClear(){
                var duoGuiGe=this.search.shiFuDuoGuiGe
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.searChParamMid=''
                this.search.shiFuDuoGuiGe=duoGuiGe
            },
            handleSizeChange(val) { //默认当前页大小变化
                this.searchNoClear.size = val;
                return this.refresh();
            },
            handleCurrentChange(val) { //默认当前页变化
                this.searchNoClear.current = val;
                return this.refresh();
            },
        }
    }
</script>

<style scoped>
</style>
