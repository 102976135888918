<template>
    <el-dialog :title="title" :visible.sync="dialogVisible" @open="open" :before-close="dialogClose"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="1300px">
        <div class="x-page-container" ref="resize">
            <div class="tip">
                <p>
                    注：规格商品每种规格定义为一种商品，例：毛衣红、黄、蓝即为三种商品展示
                </p>
            </div>
            <!-- 查询区 -->
            <div class="x-page-search">
                <x-search-item label="商品信息">
                    <el-select size="small" v-model="searchSelect" slot="prepend" placeholder="请选择">
                        <el-option v-for="item in compoundOptions" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                    <el-input v-model="searChParamMid" size="small" placeholder="请输入">
                    </el-input>
                </x-search-item>

                <x-search-item label="商品分类">
                    <el-cascader size="small" style="width: 100%" v-model="search.shangPinLeiBie"
                                 :options="fenLeiOptions"
                                 :props="fenLeiProps">
                    </el-cascader>
                </x-search-item>
                <x-search-item label="品牌">
                    <el-select v-model="search.shangPinPinPai" size="small" filterable placeholder="请选择">
                        <el-option v-for="item in pinPaiOptions" :key="item.id" :label="item.pinPaiMC" :value="item.id">
                        </el-option>
                    </el-select>
                </x-search-item>
                <div class="x-search-item">
                    <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                    <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                </div>
            </div>
            <el-row :gutter="12">
                <el-col :span="8">
                    <el-card shadow="always">
                        <div style="color: rgb(153, 153, 153);font-size: 16px">商品合计</div>
                        <div style="font-size: 14px;color: rgb(153, 153, 153)">
                            <span>{{cardData.shangPinHeJi}}</span>
                            <span style="margin-left: 5px">( 种 )</span>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card shadow="always">
                        <div style="color: rgb(153, 153, 153);font-size: 16px">进价合计</div>
                        <div style="font-size: 14px;color: rgb(153, 153, 153)">
                            <span>{{cardData.jinJiaHeJi}}</span>
                            <span style="margin-left: 5px">( 元 )</span>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card shadow="always">
                        <div style="color: rgb(153, 153, 153);font-size: 16px">售出合计</div>
                        <div style="font-size: 14px;color: rgb(153, 153, 153)">
                            <span>{{cardData.yuShouHeJi}}</span>
                            <span style="margin-left: 5px">( 元 )</span>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-tabs v-model="tabName" @tab-click="tabClick">
                <el-tab-pane label="普通商品" name="puTong">
                    <el-table ref="tableFive" v-loading="table.loading" :data="table.data" :height="table.height"
                              border>
                        <el-table-column width="50" label="序号" type="index" fixed/>
                        <el-table-column prop="shangPinMC" label="商品名称"/>
                        <el-table-column prop="fenLeiMC" label="分类"/>
                        <el-table-column prop="shangPinHuoHao" label="货号"/>
                        <el-table-column prop="shangPinKuCun" label="库存"/>
                        <el-table-column prop="shangPinJinJia" label="进价"/>
                        <el-table-column prop="dianNeiLSJ" label="售价"/>
                        <el-table-column prop="yuShouHeJi" label="预售合计"/>
                        <el-table-column prop="shangPinLaiYuan" label="渠道"/>
                        <el-table-column prop="gongHuoShangMC" label="供货商">
                            <template slot-scope="{ row, $index }">
                                <span v-if="row.gongHuoShangMC">{{row.gongHuoShangMC}}</span>
                                <span v-else>无</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="品牌">
                            <template slot-scope="{ row, $index }">
                                <span v-if="row.shangPinPinPaiMC">{{row.shangPinPinPaiMC}}</span>
                                <span v-else>无</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination background
                                   :total="table.total"
                                   @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange"
                                   layout="total, sizes, prev, pager, next, jumper"/>
                </el-tab-pane>
                <el-tab-pane label="规格商品" name="guiGe">
                    <el-table ref="tableFive" v-loading="table.loading" :data="table.data" :height="table.height"
                              border>
                        <el-table-column width="50" label="序号" type="index" fixed/>
                        <el-table-column prop="shangPinMC" label="商品名称"/>
                        <el-table-column prop="fenLeiMC" label="分类"/>
                        <el-table-column prop="guiGeHuoHao" label="货号"/>
                        <el-table-column prop="guiGeShangPinKuCun" label="库存"/>
                        <el-table-column prop="guiGeShangPinJinJia" label="进价"/>
                        <el-table-column prop="guiGeShangPinLSJ" label="售价"/>
                        <el-table-column prop="yuShouHeJi" label="预售合计"/>
                        <el-table-column prop="shangPinLaiYuan" label="渠道"/>
                        <el-table-column label="供货商">
                            <template slot-scope="{ row, $index }">
                                <span v-if="row.gongHuoShangMC">{{row.gongHuoShangMC}}</span>
                                <span v-else>无</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="品牌">
                            <template slot-scope="{ row, $index }">
                                <span v-if="row.shangPinPinPaiMC">{{row.shangPinPinPaiMC}}</span>
                                <span v-else>无</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination background
                                   :total="table.total"
                                   @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange"
                                   layout="total, sizes, prev, pager, next, jumper"/>
                </el-tab-pane>
            </el-tabs>
        </div>
    </el-dialog>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import {getSelectList} from "@/service/mdgl/MenDianHangYe"
    import {getPinPaiSelectList} from "@/service/spgl/ShangPinPinPai"
    import {mainTree} from "@/service/spgl/ShangPinFenLei"
    import XTableBase from "@/components/x/XTableBase";

    export default {
        name: "KuCunBaoBiaoDetail",
        mixins: [XTableBase],
        components: {},
        data() {
            this.refreshService = service.kuCunTongJiDetailList;
            this.service = service;
            this.searchNoClear = {current: 1, size: 10};
            this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            return {
                title: "库存统计报表",
                options: [],
                dialogVisible: false,
                cardData: {},
                menDianId: "",
                tabName: "puTong",
                table: {
                    loading: false,
                    data: [],
                    total: 0,
                    height: 500
                },
                searChParamMid: '',
                compoundOptions: [
                    {id: 1, name: '名称'},
                    {id: 2, name: '货号'},
                    {id: 3, name: '条码'},
                ],
                searchSelect: 1,
                search: {
                    shangPinMC: '',
                    shangPinHuoHao: '',
                    shangPinTiaoMa: '',
                    shangPinLeiBie: '',
                    shangPinPinPai: '',
                    guiShuMenDian: '',
                    shiFuDuoGuiGe: "false",
                },
                fenLeiOptions: [],
                pinPaiOptions: [],
                fenLeiProps: {
                    emitPath: false,
                    value: "id",
                    label: "name",
                    children: "subNodes",
                    checkStrictly: false,
                    multiple: false
                },
            }
        },
        mounted() {
        },
        methods: {
            open() {
                this.getFenLeiOptions()
                this.getPinPaiOptions()
                this.getCardData()
                this.refresh()
            },
            dialogClose() {
                this.handleClear()
                this.search.shiFuDuoGuiGe = false
                this.tabName = "puTong"
                this.table.data = []
                this.cardData = {}
                this.dialogVisible = false
            },
            tabClick(tab, event) {
                if (tab.index == 0) {
                    this.search.shiFuDuoGuiGe = false
                    this.getCardData()
                } else {
                    this.search.shiFuDuoGuiGe = true
                    this.getCardData()
                }
                this.refresh()
            },
            getCardData() {
                this.service.kuCunTongJiList(this.search).then((res) => {
                    if (res.data) {
                        this.cardData = res.data.find(item => item.guiShuMenDian == this.search.guiShuMenDian)
                        console.log("这是查询出来的相关数据：", this.cardData)
                    }
                })
            },
            //获取分类
            getFenLeiOptions() {
                mainTree().then((response) => {
                    this.fenLeiOptions = response.data
                })
            },
            //获取商品品牌
            getPinPaiOptions() {
                getPinPaiSelectList().then((response) => {
                    this.pinPaiOptions = response.data
                })
            },
            handleSearch() {
                if (this.searchSelect == 1) {
                    this.search.shangPinMC = this.searChParamMid
                } else if (this.searchSelect == 2) {
                    this.search.shangPinHuoHao = this.searChParamMid
                } else if (this.searchSelect == 3) {
                    this.search.shangPinTiaoMa = this.searChParamMid
                }
                this.searchNoClear.current = 1;
                this.refresh();
            },
            //清空
            handleClear() {
                //保留当前门店和是否多规格的默认条件
                var guiShuMD=this.search.guiShuMenDian
                var duoGuiGe=this.search.shiFuDuoGuiGe
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.searChParamMid=''
                this.search.guiShuMenDian=guiShuMD
                this.search.shiFuDuoGuiGe=duoGuiGe
            },
            handleSizeChange(val) { //默认当前页大小变化
                this.searchNoClear.size = val;
                return this.refresh();
            },
            handleCurrentChange(val) { //默认当前页变化
                this.searchNoClear.current = val;
                return this.refresh();
            },
        }
    }
</script>

<style scoped>

    .tip {
        padding: 8px 16px;
        background-color: #ecf8ff;
        border-radius: 4px;
        border-left: 5px solid #50bfff;
        font-size: 14px;
        color: #409EFF;
    }
</style>
